import Color from "color";


// export const green =  '#35D721';
// export const red =    '#DD1A10';
// export const green  = '#009f49'
// export const red    = '#BD0D26'
export const green  = '#20A55D'
export const red    = '#B22235'
export const yellow = '#FFDE00'
export const orange = '#F58A00'
export const blue   = '#1666EB'
export const gray   = '#969696'
export const purple = '#892ABF'
export const white  = '#FFFFFF'
export const black  = '#000000'

export const colorNames = ['blue', 'gray', 'red', 'green', 'orange', 'purple', 'yellow']

// adjust this number upwards to make the TradingView palette brighter and downwards for darker
export const lightMiddleShadeIndex = 9
export const darkMiddleShadeIndex = 9

export const numShades = 20 // if you change this, see vuetify.js colors that hardcode indexes

// these parameters are expressed in terms of numShades:
export const pageShade = 2
export const printContrast = 15;
// vuetify darken. values are added/substracted from the middleShadeIndex. use positive numbers here.
export const colorContrast = 4;
export const darken1 = 3;
export const lighten1 = 3;
export const darken2 = 6;
export const lighten2 = 6;


function shadesOf(rgb, middleShadeIndex) {
    const c = new Color(rgb)
    const lights = []
    const darks = []
    const numLightShades = middleShadeIndex + 1
    const numDarkShades = numShades - numLightShades
    for (let j=0; j<numLightShades; j++) {
        const ratio = j/(numLightShades-1);
        lights.push(c.lighten(ratio).rgb().toString())
    }
    // start iteration at 1 because the unaltered color was already included with the lights
    for (let j=1; j<=numDarkShades; j++) {
        const ratio = j/numDarkShades;
        darks.push(c.darken(ratio).rgb().toString())
    }
    return [...lights.toReversed(), ...darks]
}

export const light = {
    blues: shadesOf(blue, lightMiddleShadeIndex),
    grays: shadesOf(gray, lightMiddleShadeIndex),
    reds: shadesOf(red, lightMiddleShadeIndex),
    greens: shadesOf(green, lightMiddleShadeIndex),
    oranges: shadesOf(orange, lightMiddleShadeIndex),
    purples: shadesOf(purple, lightMiddleShadeIndex),
    yellows: shadesOf(yellow, lightMiddleShadeIndex),
}

export const tvLightTheme = {
    "color1": light.blues,
    "color2": light.grays,
    "color3": light.reds,
    "color4": light.greens,
    "color5": light.oranges,
    "color6": light.purples,
    "color7": light.yellows,
    "white": white,
    "black": black,
}

export const dark = {
    blues: shadesOf(blue, darkMiddleShadeIndex),
    grays: shadesOf(gray, darkMiddleShadeIndex),
    reds: shadesOf(red, darkMiddleShadeIndex),
    greens: shadesOf(green, darkMiddleShadeIndex),
    oranges: shadesOf(orange, darkMiddleShadeIndex),
    purples: shadesOf(purple, darkMiddleShadeIndex),
    yellows: shadesOf(yellow, darkMiddleShadeIndex),
}

export const tvDarkTheme = {
    "color1": dark.blues,
    "color2": dark.grays,
    "color3": dark.reds,
    "color4": dark.greens,
    "color5": dark.oranges,
    "color6": dark.purples,
    "color7": dark.yellows,
    "white": white,
    "black": black,
}

export const tvCustomThemes = {light: tvLightTheme, dark: tvDarkTheme};
// console.log('themes', tvLightTheme);
