<template>
  <router-view/>
  <support-chat/>
</template>

<script setup>
import SupportChat from "@/components/SupportChat.vue";
import {detectChain} from "@/blockchain/wallet.js";
detectChain()
</script>
