// Composables
import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    component: () => import('@/corp/CorpLayout.vue'),
    path:'/home',
    children: [
      {
        name: 'Home',
        path: '/home',
        component: () => import('@/corp/Home.vue'),
      },
      {
        name: 'HowItWorks',
        path: '/home/how-it-works',
        component: () => import('@/corp/HowItWorks.vue'),
      },
    ]
  },
  {
    path: '/',
    component: () => import('@/layouts/chart/ChartLayout.vue'),
    children: [
      {
        name: 'App',
        path: '/',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/components/chart/ChartVault.vue'),
      },
      {
        name: 'Order',
        path: '/order',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/components/chart/ChartPlaceOrder.vue'),
      },
      {
        name: 'Assets',
        path: '/assets',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/components/chart/ChartVault.vue'),
      },
      {
        name: 'Status',
        path: '/status',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/components/chart/ChartStatus.vue'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
