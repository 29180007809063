import {getToken} from "@/blockchain/token.js";

let native = false // whether native browser notifications are allowed

Notification.requestPermission()
  .then(permission => {
    console.log(`notification permission: ${permission}`);
    native = permission === 'granted'
  })
  .catch(error => {
    console.error(`notification permission error: ${error}`);
    native = false;
  });


export function notify(title, message=null) {
    if (native) {
        const options = {
            renotify: true,
            tag: title,
        }
        if (message!==null)
            options.body = message
        new Notification(title, options)
    }
    console.log('notify:', title, message)
}


export async function notifyFillEvent(chainId, status, trancheIndex, fill) {
    let low = status.order.tokenIn
    let high = status.order.tokenOut
    if (high<low)
        [high, low] = [low, high]
    const baseAddr = status.order.inverted ? high : low
    const quoteAddr = status.order.inverted ? low : high
    const buy = status.order.tokenIn === quoteAddr
    const [base, quote] = await Promise.all([getToken(chainId, baseAddr), getToken(chainId, quoteAddr)]);
    const baseAmount = Number(buy ? fill.filledOut : fill.filledIn) * 10**-base.d
    const quoteAmount = Number(buy ? fill.filledIn : fill.filledOut) * 10**-quote.d
    const average = quoteAmount / baseAmount
    const title = `${buy?"Bought":"Sold"} ${baseAmount.toPrecision(5)} ${base.s}`;
    const msg = title +
        ` for ${quoteAmount.toPrecision(5)} ${quote.s} average ${average.toPrecision(5)} ${base.s}/${quote.s}}`;
    // todo buy/sell arrow icon
    notify(title)
}
