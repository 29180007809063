/**
 * plugins/vuetify.js
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Composables
import {createVuetify} from 'vuetify'
import {
    colorContrast,
    dark,
    darken1,
    darkMiddleShadeIndex,
    light,
    lightMiddleShadeIndex, numShades, pageShade,
    printContrast
} from "../../theme.js";


function makeColors(isLight) {
    const c = isLight ? light : dark;
    const k = isLight ? c.grays : c.grays.toReversed()  // k=0 is background and k=19 is text
    const base = isLight ? numShades-colorContrast-1 : colorContrast
    const ink = k[printContrast]  // text color
    function darken(cols,shades) {return cols[base+(isLight?-shades:shades)]}
    // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
    return {
        background: k[pageShade],
        surface: k[pageShade],
        'surface-bright': k[pageShade],
        'surface-light': k[pageShade+2],
        'surface-variant': k[14],
        'on-surface-variant': k[pageShade+2],
        primary: c.greens[base],
        'primary-darken-1': darken(c.greens, darken1),
        secondary: c.blues[base],
        'secondary-darken-1': darken(c.blues, darken1),
        'grey-darken-1': k[14],
        success: c.greens[base],
        warning: c.yellows[base],
        info: c.blues[base],
        error: c.reds[base],
        "on-background": ink,
        "on-surface": ink,
        "on-primary": ink,
        "on-secondary": ink,
        "on-success": ink,
        "on-info": ink,
        "on-warning": ink,
        "on-error": ink,
    }
}

const lightColors = makeColors(true)
const darkColors = makeColors(false);

export default createVuetify({
  theme: {
    defaultTheme: 'dark',
    themes: {
      light: {
        colors: lightColors,
        dark: false,
        variables: {},
      },
      dark: {
        colors: darkColors,
        dark: true,
        variables: {},
      },
    },
  },
})
