/**
 * plugins/index.js
 *
 * Automatically included in `./src/main.js`
 */

// Plugins
import { loadFonts } from './webfontloader'
import vuetify from './vuetify'
import {pinia} from '../store/pinia.js'
import router from '../router'
import vsp from "vue-scroll-picker";

import "vue-scroll-picker/lib/style.css";

export function registerPlugins (app) {
  loadFonts().catch((e)=>console.error('Could not load fonts!',e))
  app
    .use(vuetify)
    .use(router)
    .use(pinia)
    .use(vsp)
}
